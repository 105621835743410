const routes = [

    {
        path: '/beneficiaries',
        name: 'beneficiaries.index',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Bénéficiaire", authRequired: true,
        },
        component: () => import('../views/beneficiaries/index.vue')
    },
    {
        path: '/beneficiaries/create',
        name: 'beneficiaries.create',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Nouveau bénéficiare", authRequired: true,
        },
        component: () => import('../views/beneficiaries/create.vue')
    },
    {
        path: '/beneficiaries/:id',
        name: 'beneficiaries.show',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Détails agent", authRequired: true,
        },
        component: () => import('../views/beneficiaries/show.vue')
    },
    {
        path: '/beneficiaries/:id/edit',
        name: 'beneficiaries.edit',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Bénéficiaire", authRequired: true,
        },
        component: () => import('../views/beneficiaries/create.vue')
    },
    {
        path: '/sites',
        name: 'sites.index',
        meta: {
            middleware: [
                //auth,
            ],
            //title: i18n.$t('support-types'), authRequired: true,
            title: "Sites", authRequired: true,
        },
        component: () => import('../views/sites/index.vue')
    },
    {
        path: '/sites/:id',
        name: 'sites.show',
        meta: {
            middleware: [
                //auth,
            ],
            //title: i18n.$t('support-types'), authRequired: true,
            title: "Détails du site", authRequired: true,
        },
        component: () => import('../views/sites/show.vue')
    },
    {
        path: '/companies',
        name: 'companies.index',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Entreprises", authRequired: true,
        },
        component: () => import('../views/companies/index.vue')
    },
    {
        path: '/infrastructures',
        name: 'infrastructures.index',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Infrastructures", authRequired: true,
        },
        component: () => import('../views/infras/index.vue')
    },
    {
        path: '/infrastructures/create',
        name: 'infrastructures.create',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Infrastructures", authRequired: true,
        },
        component: () => import('../views/infras/create.vue')
    },
    {
        path: '/infrastructures/:id/edit',
        name: 'infrastructures.edit',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Infrastructures", authRequired: true,
        },
        component: () => import('../views/infras/create.vue')
    },
    {
        path: '/infrastructures/:id',
        name: 'infrastructures.show',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Infrastructures", authRequired: true,
        },
        component: () => import('../views/infras/show.vue')
    },
    {
        path: '/fittings',
        name: 'fittings.index',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Aménagements", authRequired: true,
        },
        component: () => import('../views/fittings/index.vue')
    },
    {
        path: '/followups',
        name: 'followups.index',
        meta: {
            middleware: [
                //auth,
            ],
            title: "Suivis", authRequired: true,
        },
        component: () => import('../views/followups/index.vue')
    },
];


export default routes;