//import auth from "@/router/middlewares/auth";

import auth from "@/router/middlewares/auth";

export default [
    //customs
    {
        path: '/departements',
        name: 'departements.index',
        meta: {
             middleware: [
        //auth,
      ],
            title: "Départements", authRequired: true,
        },
        component: () => import('../../views/configs/common/departements/index.vue')
    },
    {
        path: '/fitting-types',
        name: 'fitting-types.index',
        meta: {
             middleware: [
                 //auth,
             ],
            //title: i18n.$t('support-types'), authRequired: true,
            title: "Type d'amenagement", authRequired: true,
        },
        component: () => import('../../views/configs/fitting-types/index.vue')
    },
    {
        path: '/infra-types',
        name: 'infras-types.index',
        meta: {
             middleware: [
                 //auth,
             ],
            //title: i18n.$t('support-types'), authRequired: true,
            title: "Type d'infrastructure", authRequired: true,
        },
        component: () => import('../../views/configs/infras-types/index.vue')
    },
    {
        path: '/departements',
        name: 'departements.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Départements", authRequired: true,
        },
        component: () => import('../../views/configs/common/departements/index.vue')
    },
    {
        path: '/communes',
        name: 'communes.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Communes", authRequired: true,
        },
        component: () => import('../../views/configs/common/communes/index.vue')
    },
    {
        path: '/villages',
        name: 'villages.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Villages", authRequired: true,
        },
        component: () => import('../../views/configs/common/villages/index.vue')
    },
    {
        path: '/arrondissements',
        name: 'arrondissements.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Arrondissement", authRequired: true,
        },
        component: () => import('../../views/configs/common/arrondissements/index.vue')
    },
    {
        path: '/roles',
        name: 'roles.index',
        meta: {
            middleware: [
                auth,
            ],
            title: "Rôles", authRequired: true,
        },
        component: () => import('../../views/configs/common/roles/index.vue')
    },
    {
        path: '/roles/:id/edit',
        name: 'roles.edit',
        meta: {
            middleware: [
                auth,
            ],
            title: "Rôles", authRequired: true,
        },
        component: () => import('../../views/configs/common/roles/create.vue')
    },
    {
        path: '/roles/create',
        name: 'roles.create',
        meta: {
            middleware: [
                auth,
            ],
            title: "Rôles", authRequired: true,
        },
        component: () => import('../../views/configs/common/roles/create.vue')
    },

    {
        path: '/users',
        name: 'users.index',
        meta: {
             middleware: [
                 //auth,
             ],
            //title: i18n.$t('support-types'), authRequired: true,
            title: 't-User', authRequired: true,
        },
        component: () => import('../../views/configs/User/index.vue')
    },



]